import React, { Fragment, useState, useEffect } from 'react'
import { Button, Modal, Radio, Label, Checkbox, TextInput, ToggleSwitch } from 'flowbite-react'
import { useDispatch, useSelector } from 'react-redux'
import { setDetailProduct, setProductDetail, setShowConfigModal } from '../redux/productSlice'
import { patchProduct } from '../services/ProductAPI';

function ProductModal(props) {
    const [show, setShow] = useState(props.show)

    const [botValue, setBotValue] = useState({ 'first': false, 'second': false, 'third': false })

    const [almaty, setAlmaty] = useState(false)
    const [astana, setAstana] = useState(false)
    const [regions, setRegions] = useState(false)
    const [autoRisePrice, setAutoRisePrice] = useState(true)

    const detailProduct = useSelector(state => state.product.detailProduct)

    const dispatch = useDispatch()

    const onClick = () => {
        dispatch(setDetailProduct('loading'))
        cleanDetail()
        setShow(false)
        dispatch(setShowConfigModal(false))
    }

    let productBotConfig
    let kaspiBotName //= detailProduct != 'loading' ? detailProduct.kaspi_bot_config.kaspi_bot : false

    let getMaxPrice //= detailProduct != 'loading' ? detailProduct.kaspi_bot_config.max_price : ''
    let getMinPrice// = detailProduct != 'loading' ? detailProduct.kaspi_bot_config.min_price : ''

    const [maxPrice, setMaxPrice] = useState(getMaxPrice)
    const [minPrice, setMinPrice] = useState(getMinPrice)

    let getStep //= detailProduct != 'loading' ? detailProduct.kaspi_bot_config.step : ''
    const [step, setStep] = useState(getStep)

    const changeCity = (city) => {
        if (city == 'almaty') {
            setAlmaty(almaty ? false : true)
        } else if (city == 'astana') {
            setAstana(astana ? false : true)
        } else if (city == 'regions') {
            setRegions(regions ? false : true)
        }
    }

    const cleanDetail = () => {
        setAlmaty('')
        setAstana('')
        setRegions('')
        setBotValue({ 'first': false, 'second': false, 'third': false })
        setAutoRisePrice(false)
        setStep('')
        setMaxPrice('')
        setMinPrice('')
    }

    useEffect(() => {
        if (detailProduct != 'loading') {
            if (detailProduct.kaspi_bot_config.kaspi_bot === 1) {
                setBotValue({ 'first': true, 'second': false, 'third': false })
            } else if (detailProduct.kaspi_bot_config.kaspi_bot === 2) {
                setBotValue({ 'first': false, 'second': true, 'third': false })
            } else if (detailProduct.kaspi_bot_config.kaspi_bot === 3) {
                setBotValue({ 'first': false, 'second': false, 'third': true })
            }
        }
        kaspiBotName = detailProduct != 'loading' ? detailProduct.kaspi_bot_config.kaspi_bot : false

        getMaxPrice = detailProduct != 'loading' ? detailProduct.kaspi_bot_config.max_price : ''
        getMinPrice = detailProduct != 'loading' ? detailProduct.kaspi_bot_config.min_price : ''
        getStep = detailProduct != 'loading' ? detailProduct.kaspi_bot_config.step : ''
        setMaxPrice(getMaxPrice)
        setMinPrice(getMinPrice)
        setStep(getStep)
        setAlmaty(detailProduct != 'loading' ? detailProduct.kaspi_bot_config.selected_city.almaty : false)
        setAstana(detailProduct != 'loading' ? detailProduct.kaspi_bot_config.selected_city.astana : false)
        setRegions(detailProduct != 'loading' ? detailProduct.kaspi_bot_config.selected_city.regions : false)

        setAutoRisePrice(detailProduct != 'loading' ? detailProduct.kaspi_bot_config.auto_raise_price : false)

    }, [dispatch, detailProduct, productBotConfig])

    const changeBotValue = (first = false, second = false, third = false) => {
        setBotValue('')
        setBotValue(
            { 'first': first, 'second': second, 'third': third }
        )
    }

    let bot
    let findBotValue = findTrueKey(botValue)
    if (findBotValue === 'first') {
        bot = 1
    } else if (findBotValue === 'second') {
        bot = 2
    } else if (findBotValue === 'third') {
        bot = 3
    }




    productBotConfig = {
        "kaspi_bot": bot,
        "step": step,
        "min_price": minPrice,
        "max_price": maxPrice,
        "auto_raise_price": autoRisePrice,
        "selected_city": {
            "almaty": almaty,
            "astana": astana,
            "regions": regions
        }
    }


    const updateProduct = () => {
        dispatch(patchProduct({ 'code': detailProduct.code, 'kaspi_bot_config': productBotConfig }))
        dispatch(setDetailProduct('loading'))
        cleanDetail()
        setShow(false)
        dispatch(setShowConfigModal(false))
    }


    return (
        <Fragment>
            <Modal
                show={show}
                onClose={onClick}
            >
                <Modal.Header>
                    Настройки товара
                </Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <p>
                            Режим бота
                        </p>
                        <div className='grid grid-cols-2 gap-4'>
                            <div className='grid gap-y-1'>
                                <div className="flex items-center gap-2">
                                    <Radio
                                        id="odna-cena"
                                        name="botValue"
                                        value="first"
                                        checked={botValue.first}
                                        onChange={val => changeBotValue(true, false, false)}
                                    />
                                    <Label htmlFor="odna-cena">
                                        Одна цена по Алматы
                                    </Label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Radio
                                        id="po-gorodam"
                                        name="botValue"
                                        value="second"
                                        checked={botValue.second}
                                        onChange={val => changeBotValue(false, true, false)}
                                    />
                                    <Label htmlFor="po-gorodam">
                                        Выставление по городам
                                    </Label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Radio
                                        id="pervy-po-nalichiy"
                                        name="botValue"
                                        value="third"
                                        checked={botValue.third}
                                        onChange={val => changeBotValue(false, false, true)}
                                    />
                                    <Label htmlFor="pervy-po-nalichiy">
                                        Первый по наличию
                                    </Label>
                                </div>
                            </div>
                            <div className='grid gap-y-0'>
                                <div className="flex items-center gap-2">
                                    <Checkbox className={botValue.first && 'text-gray-200'} id="almaty_bot" checked={almaty} onChange={e => changeCity('almaty')} disabled={botValue.first && true} />
                                    <Label htmlFor="almaty_bot" className={botValue.first && 'text-gray-200'}>
                                        Алматы
                                    </Label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Checkbox className={botValue.first && 'text-gray-200'} id="astana_bot" checked={astana} onChange={e => changeCity('astana')} disabled={botValue.first && true} />
                                    <Label htmlFor="astana_bot" className={botValue.first && 'text-gray-200'}>
                                        Астана
                                    </Label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Checkbox className={botValue.first && 'text-gray-200'} id="regions_bot" checked={regions} onChange={e => changeCity('regions')} disabled={botValue.first && true} />
                                    <Label htmlFor="regions_bot" className={botValue.first && 'text-gray-200'}>
                                        Регионы
                                    </Label>
                                </div>
                            </div>
                        </div>
                        <p>
                            Конфигурация
                        </p>
                        <div className='columns-3'>
                            <div>
                                <div className="mb-2">
                                    <Label
                                        htmlFor="step"
                                        value="Шаг"
                                    />
                                </div>
                                <div className='xl:w-28 xs:w-20'>
                                    <TextInput
                                        id="step"
                                        type="text"
                                        sizing="xl"
                                        value={step}
                                        onChange={e => setStep(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="mb-2 block truncate">
                                    <Label
                                        className=''
                                        htmlFor="min_price"
                                        value="Минимальная цена"
                                    />
                                </div>
                                <div className='w-28 xs:w-full sm:w-28'>
                                    <TextInput
                                        id="min_price"
                                        type="text"
                                        sizing="xl"
                                        value={minPrice}
                                        onChange={e => setMinPrice(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="mb-2 block truncate">
                                    <Label
                                        htmlFor="max_price"
                                        value="Максимальная цена"
                                    />
                                </div>
                                <div className='w-28 xs:w-full sm:w-28'>
                                    <TextInput
                                        id="max_price"
                                        type="text"
                                        sizing="xl"
                                        value={maxPrice}
                                        onChange={e => setMaxPrice(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div >                    
                            <label className="inline-flex relative items-center cursor-pointer pr-2">
                                
                                <input
                                    checked={autoRisePrice}
                                    onChange={value => autoRisePrice ? setAutoRisePrice(false) : setAutoRisePrice(true)}
                                    type="checkbox" value="" className="sr-only peer" />
                                <div class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                
                            </label>
                            <p >Автоподнятие</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={updateProduct}
    disabled={!maxPrice || !minPrice || String(maxPrice).trim() === '' || String(minPrice).trim() === '' ? true : false}

                    >
                        Сохранить
                    </Button>
                    <Button
                        color="gray"
                        onClick={onClick}
                    >
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

function findTrueKey(obj) {
    let trueKey;
    for (const key in obj) {
        if (obj[key] === true) {
            trueKey = key;
            break;
        }
    }
    return trueKey;
}

export default ProductModal
