import axios from 'axios'
// import { setCategory } from '../redux/categorySlice'

export const getMonthSales = (code) => {
  
    try {
      const response = axios.get(`https://dev.nemo-monitoring.kz/api/v1/get-sales/${code}/`)
      console.log([].concat(response.data).reverse())
      return response.data
    } catch (e) {
      alert(e)
    }
}

export const assetsApi = {
  async getSales(code, dayCount) {
      return await axios.get(`https://dev.nemo-monitoring.kz/api/v1/get-sales/${code}/?count=${dayCount}`)
  }
}

export default assetsApi;