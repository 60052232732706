import { useState, useEffect } from 'react';
import { Dropdown, Label } from 'flowbite-react';

const ItemsPerPageDropdown = () => {
    const [itemsPerPage, setItemsPerPage] = useState(50); // Значение по умолчанию 50

    // Загрузка значения из localStorage при монтировании компонента
    useEffect(() => {
        const savedItemsPerPage = localStorage.getItem('itemsPerPage');
        if (savedItemsPerPage) {
            setItemsPerPage(parseInt(savedItemsPerPage));
        }
    }, []);

    // Сохранение нового значения в localStorage
    const handleItemsPerPageChange = (value) => {
        const newItemsPerPage = parseInt(value);
        setItemsPerPage(newItemsPerPage);
        localStorage.setItem('itemsPerPage', newItemsPerPage);
    };

    return (
        <div className='pr-2'>
        <Dropdown label={`${itemsPerPage} элементов на странице`}
                  dismissOnClick={true}
                  outline={true}
                  color="light"
                  fullSized={true}>
            <Dropdown.Item onClick={() => handleItemsPerPageChange(50)}>
                <div>
                    <div className="mb-2 w-full">
                        <Label htmlFor="itemsPerPage50" value="50 элементов на странице" />
                    </div>
                </div>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleItemsPerPageChange(100)}>
                <div>
                    <div className="mb-2 w-full">
                        <Label htmlFor="itemsPerPage100" value="100 элементов на странице" />
                    </div>
                </div>
            </Dropdown.Item>
        </Dropdown>
            </div>
    );
};

export default ItemsPerPageDropdown;
